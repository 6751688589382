<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay
          :show="loader"
          rounded="sm"
          variant="dark"
          :opacity="0.1"
        >
          <b-card title="Add New Issue">
            <validation-observer ref="createIssueValidator">
              <b-form @submit.prevent="onSubmit">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Name"
                      label-for="name"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="form.name"
                          name="name"
                          placeholder="Enter issue name"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Description"
                      label-for="description"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="description"
                        rules="required"
                      >
                        <b-textarea
                          id="description"
                          v-model="form.description"
                          name="description"
                          placeholder=""
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Recommended time"
                      label-for="averagetime"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="average time"
                        rules="required"
                      >
                        <b-form-select
                          id="averagetime"
                          v-model="form.average_time"
                          :options="timeOptions"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        type="submit"
                      >
                        Create
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const businessITSupportModule = createNamespacedHelpers('businessITSupport')

export default {
  data() {
    return {
      loader: false,
      form: {
        name: '',
        description: '',
        average_time: null,
      },
      timeOptions: [
        { value: null, text: 'Please select one option', disabled: true },
        { value: '30 min', text: '30 min' },
        { value: '1 Hour', text: '1 Hour' },
        { value: '1.5 Hour', text: '1.5 Hour' },
        { value: '2 Hour', text: '2 Hour' },
        { value: '2.5 Hour', text: '2.5 Hour' },
        { value: '3 Hour', text: '3 Hour' },
        { value: '3.5 Hour', text: '3.5 Hour' },
        { value: '4 Hour', text: '4 Hour' },
        { value: '15-30 min', text: '15-30 min' },
        { value: '30-60 min', text: '30-60 min' },
        { value: '1-2 Hour', text: '1-2 Hour' },
        { value: '2-3 Hour', text: '2-3 Hour' },
        { value: '1-3 Hour', text: '1-3 Hour' },
        { value: '2-4 Hour', text: '2-4 Hour' },
        { value: '3-4 Hour', text: '3-4 Hour' },
      ],
    }
  },
  methods: {
    ...businessITSupportModule.mapActions(['ADD_BS_ISSUE']),

    onSubmit() {
      this.$refs.createIssueValidator.validate().then(async success => {
        if (success) {
          try {
            this.loader = true
            const resp = await this.ADD_BS_ISSUE(this.form)
            if (resp) {
              this.form.name = ''
              this.form.description = ''
              this.form.average_time = null
              this.$nextTick(() => {
                this.$refs.createIssueValidator.reset()
              })
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Issue created!',
                  icon: 'checkIcon',
                  variant: 'success',
                  text: 'The issue has been created successfully!',
                },
              })
            }
            this.loader = false
          } catch (error) {
            this.loader = false
          }
        }
      })
    },
  },
}
</script>
